.App {
  height: 100vh;
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-content {
  height: 80%;
  overflow: scroll !important;
}

.lenzo-logo-text {
  margin: 2rem;
}
