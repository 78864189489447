.collections-container {
    height: 80%;
    width: 90%;
}

.collections-header {
    height: 15%;
    padding: 40px;
}

.collections-content {
    height: 85%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.collection-container {
    height: 100%;
    width: 100%;
    background: aqua;
}

.collection-photo {
    right: 0;
    padding: 15px;
    border-radius: 5px;
    overflow: hidden;
}

.hamburger-menu {
    position: absolute;
    top: 0;
    right: 0;
}

.exit-collection-detail {
    position: absolute;
    top: 10px;
    right: 1px;
}
